import { store } from "../../../../app/store";
import { getWorkBlockAssignedTasksSorted, isDesktopView } from "../../../../shared/utils/utils";
import { ETaskSource, ETaskStatus, IMessageDataTask } from "../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface";
import { defaultHourHeightDesktop, defaultHourHeightMobile, ICalendarEvent } from "./CalendarDay";
import { addMinutes } from "date-fns";
import SassVariables from "../../../../styles/style.module.scss";

export function convertSingleItemToCalendarEvent(el: IMessageDataTask, hourHeight?:number, startingHour?:number): ICalendarEvent {
  hourHeight = hourHeight || (isDesktopView() ? defaultHourHeightDesktop : defaultHourHeightMobile)
  startingHour = startingHour || 0;

  const defaultDuration = 30; // Default duration in minutes
  const calcDuration = el.duration && el.duration / 60 > 15 ? el.duration / 60 : defaultDuration;
  const isWorkBlock = el.isEvent && el.isWorkBlock;
  const start = new Date(el.workTime!);
  const end = addMinutes(start, calcDuration);

  const startHour = start.getHours();
  const startMinutes = start.getMinutes();
  const endHour = end.getHours(); 
  const endMinutes = end.getMinutes();

  const endAdjustedHour = (endHour === 0 && endHour < startHour) ? 24 : endHour; // Treat 12AM correctly
  const startOffset = startHour * hourHeight + (startMinutes * hourHeight) / 60;
  const endOffset = endAdjustedHour * hourHeight + (endMinutes * hourHeight) / 60;
  const top = startOffset - (startingHour * hourHeight);
  const height = Math.abs(endOffset - startOffset);

  const allTasks = store.getState().StageTasksReducer.allTasks;
  const currentTaskPlacement = store.getState().StagePlannerReducer.currentTaskPlacement;

  // calc event background color
  let backgroundColor = 'transparent';
  if (!el.isWorkBlock) {
    if(el.isEvent && el?.source !== ETaskSource.Internal) {
      backgroundColor = SassVariables.Neutral3Color;
    }
    else if(el?.tags && el?.tags?.length > 0){
      const firstTagColor = el.tags[0].color || "#FFF";
      backgroundColor = firstTagColor === "transparent" ? "#FFF" : firstTagColor;
    }
    else backgroundColor = "#FFF";
  }

  return {
      id: el.id!,
      start,
      end,
      top,
      height,
      title: el?.name ? el.name : isWorkBlock ? "Work Block" : "",
      durationType: !el.duration ? '30' : isDesktopView() ? el.duration / 60 > 60 ? 'over-30' : '30' : el.duration / 60 > 30 ? 'over-30' : '30',
      duration: el.duration,
      isWorkBlock,
      backgroundColor: backgroundColor,
      titleColor: isWorkBlock ? "white" : backgroundColor !== 'transparent' && backgroundColor !== '#FFF' ? 'white' : SassVariables.MaxDarkColor,
      isRecurring: !!el.workTimeRecurrenceType,
      parentId: el.parentId,
      source: !isWorkBlock ? (el as IMessageDataTask)?.source || ETaskSource.Internal : undefined,
      isEvent: isWorkBlock ? false : el.isEvent || false,
      workBlockId: el?.workBlockId || null,
      status: el?.status || ETaskStatus.NOT_STARTED,
      relatedTasks: isWorkBlock ? getWorkBlockAssignedTasksSorted([...allTasks, currentTaskPlacement || {} as IMessageDataTask], el.id!, start) : [],
  }
}