import { usePreview } from 'react-dnd-multi-backend';
import { ConnectableElement, DragPreviewOptions } from 'react-dnd';
import { ReactElement, useEffect, useRef } from 'react';
import { useAppSelector } from '../../../app/store';
import { EDragAndDropType, getDomElementByQuerySelector, isCursorInElement } from '../../utils/utils';
import { getPreviewTransformStyle, IDragAndDropPreviewCalendarDimensions } from './DragAndDrop.utils';
import { plannerTaskListInnerContainerClassName } from '../../../app/constants';
import { IDragItem } from '../../../features/plan/calendar/calendar-day/CalendarDragAndDrop.util';
import { ETasksGroupsType } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';

export const DraggedDestinationPreview = () => {
  const ref = useRef(null);
  const preview = usePreview();
  const { dragAndDropCalendarDimensions, calendarFirstCellVisiblePortion } = useAppSelector(store => store.StagePlannerReducer);
  const { tasksFilter } = useAppSelector(store => store.StageTasksReducer);

  if (!preview.display) {
    return null;
  }

  const { item, itemType, style, monitor } = preview;
  let previewStyle = (item as IDragItem)?.previewStyle || {};
  let shouldSnap: boolean = itemType === EDragAndDropType.CALENDAR_EVENT;
  let width = previewStyle.width;
  let height = previewStyle.height;
  let backgroundColor = previewStyle.backgroundColor;
  let shouldMoveOnXAxis = true;
  let left: number = 0;

  if (itemType === EDragAndDropType.PLANNER_TO_CALENDAR_EVENT) {
    const plannerContainerEl = getDomElementByQuerySelector(`.${plannerTaskListInnerContainerClassName}`);
    const isOverPlanner = isCursorInElement(plannerContainerEl, monitor.getClientOffset()?.x, monitor.getClientOffset()?.y);
    shouldSnap = !isOverPlanner;
    if (isOverPlanner) {
      width = previewStyle.width;
      height = previewStyle.height;
      backgroundColor = previewStyle.backgroundColor;
      shouldMoveOnXAxis = false;
    }
    else {
      backgroundColor = (item as IDragItem)?.event?.backgroundColor || previewStyle?.backgroundColor;
    }
  }

  if (shouldSnap) {
    width = dragAndDropCalendarDimensions?.cellWidth ? `${dragAndDropCalendarDimensions?.cellWidth}px` : previewStyle?.width;
    height = (item as IDragItem)?.event?.height ? `${(item as IDragItem)?.event?.height}px` : previewStyle?.height;

    if (
      [EDragAndDropType.CALENDAR_EVENT, EDragAndDropType.PLANNER_TO_CALENDAR_EVENT].includes(itemType as EDragAndDropType)
      && tasksFilter === ETasksGroupsType.MY_DAY
      && dragAndDropCalendarDimensions?.cellWidth
    ) {
      left = 40;
      width = `${dragAndDropCalendarDimensions?.cellWidth - left}px`;
    }
  }

  const getCalendarDimensionsForCalcPreviewPosition = (): IDragAndDropPreviewCalendarDimensions | null => {
    if (!dragAndDropCalendarDimensions || !calendarFirstCellVisiblePortion) return null;
    return {
      ...dragAndDropCalendarDimensions,
      firstCellVisiblePortion: calendarFirstCellVisiblePortion
    }
  }

  const previewTransformAndPosition = getPreviewTransformStyle(
    monitor.getInitialSourceClientOffset(),
    monitor.getClientOffset(),
    shouldSnap,
    getCalendarDimensionsForCalcPreviewPosition(),
    shouldMoveOnXAxis,
    previewStyle?.left
  );

  const localStyle = {
    ...style,
    ...previewStyle,
    ...previewTransformAndPosition.style,
    left: `${left}px`,
    width,
    height,
    backgroundColor,
    cursor: 'move',
  };

  (item as IDragItem).snapYDroppedPosition = previewTransformAndPosition.snapY;

  return (
    <div ref={ref} style={localStyle} />
  );
}


// The custom preview using usePreview() (inside DraggedDestinationPreview component) is displayed together with the default preview in desktop.
// This component return a transparent element for  on desktop so the custom preview is displayed alone
export const TransparentDefaultPreview = ({ dragPreview }: ITransparentDefaultPreview) => {
  const transparentRef = useRef(null);

  useEffect(() => {
    if (transparentRef.current) {
      dragPreview(transparentRef.current);
    }
  }, [dragPreview]);

  return (
    <div
      ref={transparentRef}
      style={{
        position: 'fixed',
        top: -9999,
        left: -9999,
        width: 0,
        height: 0,
        opacity: 0,
      }}
    />
  )
}

interface ITransparentDefaultPreview {
  dragPreview: (elementOrNode: ConnectableElement, options?: DragPreviewOptions | undefined) => ReactElement | null
}