import { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/store';
import { createOrContinueSession } from './chat.store';
import { ResizableContainer } from './resizable-container/ResizableContainer';
import './ChatWrapper.scss';
import WelcomeScreen from './welcome-screen/WelcomeScreen';
import ProductTour from '../product-tour/ProductTour';
import { userInfoLocalStorageKey } from '../../app/constants';
import { setShouldDisplayProductTour } from '../../shared/store/shared.store';
import { getFeatureFlagByKey } from '../../shared/utils/utils';
import { IUser } from '../../app/auth/auth.interfaces';
import { FlagUtils } from '../../shared/utils/flagUtils';
import { EClientFlags } from '../../shared/components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { updateUserProfileReq } from '../../shared/components/content-frame-wrapper/settings-menu/settingsMenu.store';
import { getItemFromLocalStorage } from '../../shared/utils/localStorage.utils';
import { ApplicationInsightsApi } from '../../application-insights';

export const ChatWrapper: FunctionComponent = () => {
  const { shouldDisplayProductTour } = useAppSelector(store => store.sharedStoreReducer);
  const { userAppData } = useAppSelector(store => store.userReducer);
  // this carousel open only after registration
  const [isWelcomeScreenOpen, setIsWelcomeScreenOpen] = useState(false);
  const componentShouldLoad = useRef(true);
  const dispatch = useAppDispatch();
  const clientFlags = userAppData.data?.user?.clientFlags || getItemFromLocalStorage<IUser>(userInfoLocalStorageKey)?.clientFlags || 0;

  // Checks if the product tour has already been displayed to the user.
  const isProductTourAlreadyDisplayed = useCallback((): boolean => {
    const isProductTourDisplayed = FlagUtils.getFlag(clientFlags, EClientFlags.PRODUCT_TOUR_DISPLAYED);
    // Product tour was not displayed yet if the user is new (created on or after 2024-11-04) and the PRODUCT_TOUR_DISPLAYED client flag is not set
    if (getFeatureFlagByKey("UserCreatedIn_2024_11_4_OrLater") && !isProductTourDisplayed) return false;
    return true;
  },[clientFlags])

  const turnOnTheProductTourClientFlag = useCallback(() => {
    const updatedClientFlags = FlagUtils.setFlag(clientFlags, EClientFlags.PRODUCT_TOUR_DISPLAYED);
    dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags }));
  }, [clientFlags, dispatch]);

  const onStartSession = useCallback(() => {
    // call createOrContinueSession Api
    dispatch(createOrContinueSession())
    .unwrap()
    .then(() => {
      if(!isProductTourAlreadyDisplayed()) {
        turnOnTheProductTourClientFlag()
      }
    })
    .catch((e) => {
      ApplicationInsightsApi.trackException(e);
    });
  }, [dispatch, isProductTourAlreadyDisplayed, turnOnTheProductTourClientFlag])

  const handleProductTourAppearance = useCallback(() => {
    if(!isProductTourAlreadyDisplayed()) {
      dispatch(setShouldDisplayProductTour(true));
    }
  }, [dispatch, isProductTourAlreadyDisplayed]);

  useEffect(() => {
    if (componentShouldLoad.current) {
      componentShouldLoad.current = false;
      onStartSession();
      handleProductTourAppearance();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onStartSession, handleProductTourAppearance]);

  return (
    <div className="chat-wrapper">
      {!isWelcomeScreenOpen && <ResizableContainer />}
      {/* This carousel will be displayed only after registration. */}
      <WelcomeScreen isWelcomeScreenOpen={isWelcomeScreenOpen} setIsWelcomeScreenOpen={setIsWelcomeScreenOpen} />
      {shouldDisplayProductTour && <ProductTour />}
    </div>
  )
}